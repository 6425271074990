import RemoveIcon from "@mui/icons-material/Delete";
import UploadIcon from '@mui/icons-material/Upload';
import CheckIcon from '@mui/icons-material/Check';
import { Box, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Stack, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { uniq } from 'lodash';
import moment from 'moment';
import { Fragment, useState } from 'react';
import { ArrayField, Button, Datagrid, DateField, DateInput, FormDataConsumer, FunctionField, Labeled, NumberField, SimpleForm, SimpleShowLayout, TextField, TextInput, Toolbar, UseInputValue, number, required, useCreate, useDataProvider, useGetIdentity, useInput, useNotify, useRecordContext, useRefresh } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { ChatOrigin } from '../../models';
import { PriceInput } from '../../utils/Inputs';
import { dateInputFormatter, formatCnpj, formatPrice, getChainId, getStationId, getUserId, readXmlFile } from '../../utils/utils';
import ChatAccordion from '../ChatAccordion';
import UploadFileButton from './UploadFileButton';
import { UserRole } from "../../providers/authProvider";

const useStyles = makeStyles({
  fileNameColumn: {
    width: '200px',
  },
  iconButton: {
    width: '60px',
  }
});

const AccessKeyField: React.FC<{ source: string, label: string, emptyText: string }> = (props) => {
  const record = useRecordContext();
  return (
    <Tooltip title={record.accessKey || ''} placement="bottom">
      <Typography variant="body2">{(record[props.source] || '').slice(0, 15).concat('...')}</Typography>
    </Tooltip>
  );
}

const UploadInvoiceBulkAction = ({ selectedIds, setSelectedIds, resetInitialValues, xmlLoaded, setXmlLoaded, invoiceFillins, onReadyButton, setInvoiceFillins, setCurrentInvoice, ...props }: any) => {
  const [xmlDocument] = useState<Document>();
  const notify = useNotify();
  const refresh = useRefresh();
  const [create, { isLoading }] = useCreate();
  const dataProvider = useDataProvider();
  const { identity } = useGetIdentity();
  const classes = useStyles();

  const createChatMessage = ({ billId, subject, body, participantIds, chatFiles }: { billId: string, subject: string, body: string, chatFiles: Object, participantIds?: string[] }) => new Promise((resolve, reject) => {
    if (subject || body || chatFiles) {
      notify('Enviando sua mensagem');

      const data: any = {
        subject: subject || '(Sem assunto)',
        chatMessage: {
          body,
          files: chatFiles,
        },
        employeeId: getUserId(),
        chainId: getChainId(),
        origin: ChatOrigin.bill,
        billId,
      }

      if (participantIds) {
        data.participantIds = participantIds;
      }

      create('chats', { data }, {
        onSettled: (_data, error: any) => {
          if (error.status) {
            notify(error.message || 'Não foi possível encaminhar a mensagem, mas pode ser feito uma nova tentativa na tela de notas fiscais', { type: 'warning' });
            reject(error);
            return;
          }

          notify('Mensagem enviada');
          resolve(_data);
        }
      });
    } else {
      resolve(null);
    }
  });

  const uploadFile = async (props: any, clear: () => void) => {

    const { body, subject, chatFiles, participantIds, ...invoicePayload } = props;

    if (invoicePayload.invoices.length !== invoiceFillins.length) {
      notify('Há uma ou mais notas fiscais sem abastecimentos associados.', { type: 'warning' });
      return null;
    }

    if (uniq(invoicePayload.invoices.map(({ cnpj }: { cnpj: string }) => cnpj)).length > 1) {
      notify('Há uma ou mais notas fiscais associadas a CNPJs distintos.', { type: 'warning' });
      return null;
    }

    delete (invoicePayload.vehicleBaseName);

    if (invoicePayload.billExpiration === undefined) {
      delete (invoicePayload.billExpiration);
    }

    if (invoicePayload.invoiceFilePdf === undefined) {
      delete (invoicePayload.invoiceFilePdf);
    }

    if (invoicePayload.invoiceFiles && xmlDocument) {
      const invoiceSerie = xmlDocument.getElementsByTagName('serie')[0];
      const invoiceIssueDate = xmlDocument.getElementsByTagName('dhEmi')[0];
      const invoiceAccessKey = xmlDocument.getElementsByTagName('chNFe')[0];
      const totalInvoiceValue = xmlDocument.getElementsByTagName('vNF')[0];

      if (invoiceSerie && invoiceSerie.textContent) {
        invoicePayload.serie = +invoiceSerie.textContent;
      }

      if (invoiceIssueDate && invoiceIssueDate.textContent) {
        invoicePayload.issueDate = invoiceIssueDate.textContent;
      }

      if (invoiceAccessKey && invoiceAccessKey.textContent) {
        invoicePayload.accessKey = invoiceAccessKey.textContent;
      }

      if (totalInvoiceValue && totalInvoiceValue.textContent) {
        invoicePayload.totalInvoiceValue = +totalInvoiceValue.textContent;
      }
    }

    invoicePayload.invoices = invoicePayload.invoices.map(({ cnpj, fileName, invoiceValue, ...invoice }: { cnpj: string, fileName: string, invoiceNumber: string, invoiceValue: number }) => ({
      ...invoice,
      fillinIds: invoiceFillins.find((invoiceFillin: { invoiceNumber: string }) => invoiceFillin.invoiceNumber === invoice.invoiceNumber)?.fillinIds,
    }));

    create('bills', { data: invoicePayload }, {
      onSuccess: (data) => {
        notify('Upload feito com sucesso');

        createChatMessage({ billId: data.id, subject, body, chatFiles, participantIds }).finally(() => {
          resetInitialValues(true);
          setInvoiceFillins([]);
          clear();
          refresh();
        });
      },
      onError: (error: any) => {
        if (error.status) {
          notify(error.message || 'Não foi possível realizar o upload', { type: 'warning' });
          return;
        }
      }
    });
  }

  const UploadInvoiceToolbar = ({ handleSubmit, ...props }: { handleSubmit: (value: object, clear: () => void) => void }) => {
    const { getValues, resetField, getFieldState, ...form } = useFormContext();

    const clear = () => {
      resetField("invoiceFiles");
      resetField("invoiceFilePdf");
      resetField("billNumber");
      resetField("billValue");
      resetField("billExpiration");
      resetField("invoiceNumber");
      resetField("destCNPJ");
      resetField("serie");
      resetField("issueDate");
      resetField("accessKey");
      resetField("totalInvoiceValue");
      resetField("subject");
      resetField("body");
      resetField("chatFiles");
      resetField("vehicleBaseName");
    }

    return (
      <Toolbar {...props} >
        <Button
          startIcon={isLoading ? <CircularProgress size={20} /> : <UploadIcon style={{ fontSize: 20 }} />}
          disabled={isLoading}
          label="Enviar Nota"
          onClick={form.handleSubmit(() => handleSubmit(getValues(), clear))}
          variant="contained"
          size="medium"
        />
      </Toolbar>
    );
  };

  const getVehicleBaseByCnpjApi = (cnpj: string, vehicleBaseNameInput: UseInputValue) => {
    // @ts-ignore
    dataProvider.getList('vehicle-bases', { filter: { cnpj } }).then(({ data }) => {
      if (data && data.length) {
        vehicleBaseNameInput.field.onChange(data[0].name);
        setXmlLoaded((val: object) => ({ ...val, vehicleBase: data[0] }));
      }
    });
  }

  const handleFileChange = async (files: Array<Blob | { rawFile: Blob }>, invoicesInput: UseInputValue, invoiceFiles: UseInputValue, destCNPJInput: UseInputValue, totalInvoiceValueInput: UseInputValue, vehicleBaseNameInput: UseInputValue) => {
    if (!Array.isArray(files)) { throw null; }

    let totalInvoiceValue = 0;
    const invoices: Array<{ fileName: string, invoiceNumber: string, serie: string, issueDate: string, accessKey: string, cnpj: string, invoiceValue: number, totalInvoiceValue: number }> = [];

    const pendingInvoices = (invoicesInput.field.value || []).filter(({ invoiceNumber }: { invoiceNumber: string }) => !invoiceFillins.find((invoiceF: { invoiceNumber: string }) => invoiceF.invoiceNumber === invoiceNumber));

    if (files.length > (invoicesInput.field.value || []).length && pendingInvoices.length > 0) {
      invoiceFiles.field.onChange(invoiceFiles.field.value); // reset
      notify('Nota fiscal sem abastecimento vinculado, por favor, selecione os abastecimentos listados abaixo.', { type: 'warning', autoHideDuration: 10000 });
      return;
    }

    if ((files.length - invoicesInput.field.value.length) > 1) {
      files = files.slice(0, invoicesInput.field.value.length + 1);
      invoiceFiles.field.onChange(files);

      notify('Limite-se a um arquivo por vez.', { type: 'warning', autoHideDuration: 10000 });
    }

    if (!files || !files.length) {
      resetInitialValues(true);
      setInvoiceFillins([]);
      invoicesInput.field.onChange([]); // reset
      return;
    }

    let error = false;
    let xmlData: { [k: string]: any } = xmlLoaded;

    await Promise.all(files.map(async file => {
      if (!(file instanceof Blob) && file.rawFile) { file = file.rawFile; }

      const xml = await readXmlFile(file as Blob);
      totalInvoiceValue += parseFloat(xml.getElementsByTagName('vNF')[0].textContent);
      const invoiceSerie = xml.getElementsByTagName('serie')[0];
      const invoiceIssueDate = xml.getElementsByTagName('dhEmi')[0];
      const invoiceAccessKey = xml.getElementsByTagName('chNFe')[0];
      const invoiceNumber = xml.getElementsByTagName('nNF')[0];
      const invoiceCNPJ = xml.getElementsByTagName('dest')[0].getElementsByTagName('CNPJ')[0];
      const emitCNPJ = xml.getElementsByTagName('emit')[0].getElementsByTagName('CNPJ')[0];
      const invoiceValue = parseFloat(xml.getElementsByTagName('vNF')[0].textContent);

      const xmlLoaded: any = {};
      const invoiceNumbers = (invoicesInput.field.value || []).map((item: any) => item.invoiceNumber);
      const invoiceCNPJS = invoices ? uniq(invoices.map((invoice: { cnpj: string }) => invoice.cnpj)) : [];
      const invoiceNumberFormated = ("000000000" + invoiceNumber.textContent).slice(-9);

      if (invoices && invoices.find((invoice) => invoice.invoiceNumber === invoiceNumberFormated)) {
        notify('A nota fiscal já foi carregada.', { type: 'warning' });
        invoiceFiles.field.onChange(invoiceFiles.field.value); // reset
        error = true;
        return file;
      }

      if (xml && invoiceCNPJ.textContent) {
        if (invoiceCNPJS.length > 0 && !invoiceCNPJS.includes(invoiceCNPJ.textContent)) {
          notify('O CNPJ da nota fiscal não coincide com os enviados anteriormente.', { type: 'warning', autoHideDuration: 10000 });
          invoiceFiles.field.onChange(invoiceFiles.field.value); // reset
          error = true;
          return file;
        }

        destCNPJInput.field.onChange(invoiceCNPJ.textContent);
        xmlLoaded.cnpj = invoiceCNPJ.textContent;
        getVehicleBaseByCnpjApi(invoiceCNPJ.textContent, vehicleBaseNameInput);
      }

      if (xml && emitCNPJ.textContent) {
        // @ts-ignore
        const stations = await dataProvider.getList('stations', {
          filter: { cnpj: emitCNPJ.textContent },
        });

        if (stations.data.length === 0) {
          notify('Posto não consta em nosso banco de dados.', { type: 'warning' });
          invoiceFiles.field.onChange(invoiceFiles.field.value); // reset
          error = true;
          return file;
        }

        if (identity?.role === UserRole.station && stations.data[0].id !== getStationId()) {
          notify('Posto diverge do cadastrado.', { type: 'warning' });
          invoiceFiles.field.onChange(invoiceFiles.field.value); // reset
          error = true;
          return file;
        }
      }

      if (!invoiceNumbers.find((number: string) => number === invoiceNumberFormated)) {
        // @ts-ignore
        const invoicesResponse = await dataProvider.getList('invoices', {
          filter: { invoiceNumber: invoiceNumberFormated },
        });
        if (invoicesResponse.data.length > 0) {
          notify('Esta nota fiscal já foi processada e consta em nosso banco de dados.', { type: 'warning' });
          invoiceFiles.field.onChange(invoiceFiles.field.value); // reset
          error = true;
          return file;
        }
      }

      if (xml && totalInvoiceValue) {
        totalInvoiceValueInput.field.onChange(totalInvoiceValue);
      }

      xmlData = { ...xml, ...xmlLoaded };

      invoices.push({
        fileName: (file as File).name || 'Não identificado',
        invoiceNumber: invoiceNumberFormated,
        serie: invoiceSerie.textContent,
        issueDate: invoiceIssueDate.textContent,
        accessKey: invoiceAccessKey.textContent,
        cnpj: invoiceCNPJ.textContent,
        totalInvoiceValue: invoiceValue,
        invoiceValue,
      });
    }));

    if (!error) {
      resetInitialValues();
    }

    const currentInvoice = invoices[invoices.length - 1];
    setCurrentInvoice(currentInvoice);
    if (currentInvoice) {
      setSelectedIds(invoiceFillins.find((invoiceFillin: any) => invoiceFillin.invoiceNumber === currentInvoice.invoiceNumber)?.fillinIds || []);
    } else {
      resetInitialValues(true);
    }

    setXmlLoaded((val: object) => ({ ...val, ...xmlData }));

    invoicesInput.field.onChange(invoices);

    // removes all fillins linked to the removed invoice
    setInvoiceFillins((invoiceFillins: Array<{ invoiceNumber: string }>) =>
      invoiceFillins.filter((invoiceFillin: { invoiceNumber: string }) => invoices.find(invoice => invoice && invoice.invoiceNumber === invoiceFillin.invoiceNumber))
    );
  }

  const DialogConfirm = () => {
    const record = useRecordContext();
    if (!record) { return null; }

    const closeDialog = () => {
      props.setModelDialog(false);
    }

    const diff = (record.invoiceValue - props.totalValue);

    return (
      <Fragment>
        <ReadyButton headerClassName={classes.iconButton} />
        <Dialog
          fullWidth
          open={props.openDialog}
        >
          <DialogTitle textAlign="center">Divergência</DialogTitle>
          <DialogContent>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ paddingLeft: 0, color: 'text.secondary' }}>Descrição</TableCell>
                  <TableCell sx={{ paddingRight: 0, color: 'text.secondary' }} align="right">Valores</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow sx={{ '& th': { border: 0 } }}>
                  <TableCell component="th" scope="row" sx={{ paddingLeft: 0 }}>Nota fiscal</TableCell>
                  <TableCell component="th" align="right" sx={{ paddingRight: 0, fontWeight: 600 }}>{formatPrice(record.invoiceValue)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ paddingLeft: 0 }}>Abastecimentos</TableCell>
                  <TableCell component="th" align="right" sx={{ paddingRight: 0, fontWeight: 600 }}>{formatPrice(props.totalValue)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Stack flexDirection="row" justifyContent="flex-end" marginTop={1.5}>
              <Stack flexDirection="row" justifyContent="space-between" sx={{ width: '40%', minWidth: 200 }}>
                <Typography variant="body2" >Diferença</Typography>
                <Typography variant="body2" color="warning.main" fontWeight="600">{formatPrice(diff)}</Typography>
              </Stack>
            </Stack>
            <DialogActions sx={{ marginTop: 4, padding: 0 }}>
              <Button variant="outlined" onClick={closeDialog} label="ra.action.cancel" />
              <Button variant="contained" onClick={() => onReadyButton(record)} label="ra.action.confirm" />
            </DialogActions>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }

  const ReadyButton: React.FC<any> = () => {
    const record = useRecordContext();
    const checked = !!(invoiceFillins || []).find((invoice: any) => invoice.invoiceNumber === record.invoiceNumber && invoice.fillinIds?.length > 0);

    return (
      <Button
        sx={{ minWidth: 'auto' }}
        variant="outlined"
        onClick={() => onReadyButton(record)}
        startIcon={<CheckIcon style={{ marginLeft: 10 }} />}
        disabled={checked}
      />
    );
  }

  const RemoveButton: React.FC<any> = () => {
    const record = useRecordContext();
    const invoiceFiles = useInput({ source: 'invoiceFiles' });
    const invoices = useInput({ source: 'invoices' });
    const invoicesInput = useInput({ source: 'invoices' });
    const destCNPJInput = useInput({ source: 'destCNPJ' });
    const totalInvoiceValueInput = useInput({ source: 'totalInvoiceValue' });
    const vehicleBaseNameInput = useInput({ source: 'vehicleBaseName' });

    const handleRemove = (record: any) => {
      const indexRemoved = invoices.field.value?.findIndex((invoice: any) => invoice.invoiceNumber === record.invoiceNumber);

      const files = invoiceFiles.field.value.filter((item: any, index: number) => index !== indexRemoved);
      invoiceFiles.field.onChange(invoiceFiles.field.value.filter((item: any, index: number) => index !== indexRemoved));

      handleFileChange(files, invoicesInput, invoiceFiles, destCNPJInput, totalInvoiceValueInput, vehicleBaseNameInput);
    }

    return (
      <Button
        sx={{ minWidth: 'auto' }}
        variant="outlined"
        onClick={() => handleRemove(record)}
        startIcon={<RemoveIcon style={{ marginLeft: 10 }} />}
        color="warning"
      />
    );
  }

  const invoiceStyle = (record: any) => ({
    backgroundColor: (invoiceFillins || []).find((invoice: any) => invoice.invoiceNumber === record.invoiceNumber && invoice.fillinIds.length > 0) ? '#caffdb' : '#FFEBE6',
  });

  return (
    <Card sx={{ marginTop: 2 }}>
      <Box>
        <SimpleForm toolbar={<UploadInvoiceToolbar handleSubmit={uploadFile} />} sanitizeEmptyValues>
          <Grid container spacing={2} columns={12}>
            <Grid item sm={12} md={4}>
              <TextInput source="billNumber" label="Número do Boleto" inputProps={{ maxLength: 15 }} validate={[number()]} fullWidth />
            </Grid>
            <Grid item sm={12} md={4}>
              <PriceInput source="billValue" label="Valor do Boleto" locales="pt-BR" fullWidth />
            </Grid>
            <Grid item sm={12} md={4}>
              <DateInput
                source='billExpiration'
                type="date"
                label="Vencimento do Boleto"
                format={dateInputFormatter}
                parse={value => moment(value).toISOString()}
                fullWidth
              />
            </Grid>

            <Grid item md={12} sx={{ paddingTop: '0px!important' }}>
              <ChatAccordion companyId={xmlLoaded?.vehicleBase ? xmlLoaded.vehicleBase.companyId : null} disabledSelectOrigin />
            </Grid>

            <Grid item md={12}>
              <Box component="div" sx={{ p: 2, border: '1px dashed grey', height: '100%', padding: 0 }}>
                <FormDataConsumer>
                  {({ formData }) => {
                    if (!formData.invoiceFiles || formData.invoiceFiles.length === 0) {
                      return (
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 2 }}>
                          <Typography variant="subtitle1" align="center">Aguardando o arquivo XML</Typography>
                        </Box>
                      );
                    }

                    return (
                      <>
                        <Grid container sm={12}>
                          <Grid item md={4}>
                            <SimpleShowLayout record={formData as any}>
                              <TextField source="vehicleBaseName" label="Destinatário" emptyText="Não identificado" />
                            </SimpleShowLayout>
                          </Grid>
                          <Grid item md={4}>
                            <SimpleShowLayout record={formData as any}>
                              <FunctionField label="CNPJ" render={(record: any) => record.destCNPJ ? formatCnpj(record.destCNPJ) : 'Não identificado'} />
                            </SimpleShowLayout>
                          </Grid>
                          <Grid item md={4}>
                            <SimpleShowLayout record={formData as any}>
                              <NumberField source="totalInvoiceValue" label="Valor das notas" locales="pt-BR" options={{ style: 'currency', currency: 'BRL' }} emptyText="Não identificado" />
                            </SimpleShowLayout>
                          </Grid>
                          <Grid item md={12}>
                            <SimpleShowLayout record={formData as any}>
                              <ArrayField source="invoices" label="Notas Fiscais" emptyText="Não identificado">
                                <Datagrid
                                  bulkActionButtons={false}
                                  rowSx={invoiceStyle}
                                  sx={{
                                    '& .MuiTypography-root, .MuiTableCell-head': { fontSize: 12 },
                                  }}
                                >
                                  <TextField source="fileName" label="Nome" headerClassName={classes.fileNameColumn} />
                                  <TextField source="invoiceNumber" label="Número" />
                                  <FunctionField label="CNPJ" render={(record: any) => record.cnpj ? formatCnpj(record.cnpj) : 'Não identificado'} />
                                  <TextField source="serie" label="Série" emptyText="Não identificado" />
                                  <DateField source="issueDate" locales="pt-BR" label="Data de emissão" emptyText="Não identificado" showTime />
                                  <AccessKeyField source="accessKey" label="Chave de acesso" emptyText="Não identificado" />
                                  <FunctionField render={(record: any) => record && record.invoiceValue ? formatPrice(record.invoiceValue) : '--'} label="Valor da nota" />
                                  <RemoveButton headerClassName={classes.iconButton} />
                                  <DialogConfirm />
                                </Datagrid>
                              </ArrayField>
                            </SimpleShowLayout>
                          </Grid>
                        </Grid>
                      </>
                    );
                  }}
                </FormDataConsumer>
              </Box>
            </Grid>

            <Grid item md={12}>
              <UploadFileButton
                source="invoiceFiles"
                resetInitialValues={resetInitialValues}
                setXmlLoaded={setXmlLoaded}
                invoiceFillins={invoiceFillins}
                setInvoiceFillins={setInvoiceFillins}
                setCurrentInvoice={setCurrentInvoice}
                onChange={handleFileChange}
                validate={[required('Campo obrigatório')]}
              />
            </Grid>


            <Grid item md={12}>
              <Divider sx={{ marginBottom: 2 }} />
              <Labeled label="Selecione os abastecimentos" fullWidth>
                {props.children}
              </Labeled>
            </Grid>

          </Grid>
        </SimpleForm>
      </Box>
    </Card>
  );
};

export default UploadInvoiceBulkAction;