import React, { useState } from 'react';
import { Button, FileField, FileInput, NumberField, ReferenceField, SimpleForm, SimpleShowLayout, TextField, useDataProvider, useNotify, useRecordContext, useRefresh, useShowContext } from 'react-admin';
import { formatPrice, readXmlFile } from '../../utils/utils';
import { Alert, Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import CnpjField from '../CnpjField';
import SaveIcon from '@mui/icons-material/Save';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { FillinReviewStatus, FillinStatus } from '../../models';

interface InvoiceData {
  fillinId: string;
  fuelANP: string;
  amount: number;
  value: number;
  invoiceCnpj: string;
  invoiceNumber: string;
  serie: string;
  issueDate: string;
  accessKey: string;
  xmlFile: File;
  fuel?: any;
}

interface ImportInvoiceButtonProps {
  variant?: 'contained' | 'outlined' | 'text';
}

const ImportInvoiceButton: React.FC<ImportInvoiceButtonProps> = ({ variant }) => {

  const record = useRecordContext();
  if (!record) { return null; }

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [invoiceData, setInvoiceData] = useState<{ [k: string]: any } | null>(null);
  const [fileLoading, setFileLoading] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ [k: string]: any } | null>();

  const resetInitialValues = () => {
    setErrors(null);
    setInvoiceData(null);
    setFileLoading(false);
  }

  const handleOpen = (event: any) => {
    event.stopPropagation();
    setOpenDialog(true);
  }

  const handleClose = () => {
    setOpenDialog(false);
    resetInitialValues();
  }

  const invoiceValidation = (invoiceData: InvoiceData) => {
    const errors: any = {};
    if (!invoiceData) { return null; }

    if (invoiceData.fuel && invoiceData.fuel.id !== record.fuelId) {
      errors.fuel = true;
    }

    if (Math.abs(invoiceData.value - record.value) > 20) {
      errors.value = true;
    }

    if (Math.abs(invoiceData.amount - record.amount) > 0.01) {
      errors.amount = true;
    }

    setErrors(Object.keys(errors).length ? errors : null);
  }

  const handleFileChange = async (file: File) => {
    try {
      if (!file) {
        resetInitialValues();
        return null;
      }
      if (!(file instanceof Blob)) { return null; }

      setFileLoading(true);

      const xml = await readXmlFile(file as Blob);

      const productTag = xml.getElementsByTagName('det')[0].getElementsByTagName('prod')[0];
      const invoiceFuelAnp = productTag.getElementsByTagName('cProdANP')[0];
      const invoiceAmount = productTag.getElementsByTagName('qCom')[0];
      const invoiceValue = productTag.getElementsByTagName('vProd')[0];
      const invoiceCNPJ = xml.getElementsByTagName('dest')[0].getElementsByTagName('CNPJ')[0];
      const emitCNPJ = xml.getElementsByTagName('emit')[0].getElementsByTagName('CNPJ')[0];
      const invoiceNumber = xml.getElementsByTagName('nNF')[0];
      const invoiceSerie = xml.getElementsByTagName('serie')[0];
      const invoiceIssueDate = xml.getElementsByTagName('dhEmi')[0];
      const invoiceAccessKey = xml.getElementsByTagName('chNFe')[0];

      const invoiceNumberFormated = ("000000000" + invoiceNumber.textContent).slice(-9);

      const data: InvoiceData = {
        fillinId: record.id as string,
        fuelANP: invoiceFuelAnp.textContent,
        amount: invoiceAmount.textContent,
        value: invoiceValue.textContent,
        invoiceCnpj: invoiceCNPJ.textContent,
        invoiceNumber: invoiceNumberFormated,
        serie: invoiceSerie.textContent,
        issueDate: invoiceIssueDate.textContent,
        accessKey: invoiceAccessKey.textContent,
        xmlFile: file,
      };

      //@ts-ignore
      const response = await dataProvider.getList('fuels', { filter: { anpCode: data.fuelANP } });

      if (response.data.length) {
        data.fuel = response.data[0];
      }

      invoiceValidation(data);

      setInvoiceData(data);
      setFileLoading(false);
    } catch (error) {
      notify('Não foi possível verificar a nota. remova a nota e tente novamente', { type: 'error' });
      setFileLoading(false);
    }
  }

  const handleSubmit = () => {
    if (!!errors || !invoiceData) { return null; }

    const { fuel, ...data } = invoiceData;

    setSaveLoading(true);
    dataProvider.create('invoices', { data })
      .then(({ data }) => {
        notify('Nota enviada com sucesso');
        handleClose();
        refresh();
      })
      .catch((error) => {
        notify(error.message || 'Não foi possível enviar a nota', { type: 'error' });
      })
      .finally(() => {
        setSaveLoading(false);
      });
  }

  if (record?.invoiceId || record?.status !== FillinStatus.approved || record?.stationReviewStatus !== FillinReviewStatus.approved) { return <></>; }

  return (
    <>
      <Button label="Importar XML" variant={variant} onClick={handleOpen} startIcon={<FileUploadIcon />} />
      <Dialog
        fullWidth
        open={openDialog}
        onClick={(event) => event.stopPropagation()}
      >
        <DialogTitle textAlign="center">Importar XML da nota</DialogTitle>
        <DialogContent dividers>
          <Box component="fieldset" sx={{ border: '1px dashed grey' }}>
            <Legend>Detalhes do abastecimento</Legend>
            <SimpleShowLayout record={record}>
              <Grid container>
                <Grid item xs={6}>
                  <SimpleShowLayout sx={{ padding: 0 }}>
                    <ReferenceField source="fuelId" reference="fuels" label="Combustível">
                      <TextField source="name" />
                    </ReferenceField>
                    <CnpjField source="invoiceCnpj" label="CNPJ da nota" isHidden={false} />
                  </SimpleShowLayout>
                </Grid>
                <Grid item xs={6}>
                  <SimpleShowLayout sx={{ padding: 0 }}>
                    <NumberField
                      source="value"
                      label="Valor total"
                      options={{ style: 'currency', currency: 'BRL' }}
                    />
                    <NumberField
                      source="amount"
                      label="Litros"
                      options={{ style: 'unit', unit: 'liter' }}
                    />
                  </SimpleShowLayout>
                </Grid>
              </Grid>
            </SimpleShowLayout>

          </Box>

          <SimpleForm toolbar={<></>}>
            <FileInput label="Arquivo XML" source="invoice" accept="text/xml" onChange={handleFileChange}>
              <FileField source="src" title="title" />
            </FileInput>
          </SimpleForm>

          {
            fileLoading ?
              <Stack flexDirection="column" alignItems="center" justifyContent="center" gap={2}>
                <CircularProgress size={22} />
                <Typography>Verificando as informações. Por favor, aguarde</Typography>
              </Stack>
              :
              <Stack gap={1}>
                {
                  (invoiceData && invoiceData.invoiceCnpj !== record.invoiceCnpj) &&
                  <Alert variant="outlined" severity="warning">
                    O CNPJ da nota fiscal está diferente do CNPJ que a transportadora esperava {invoiceData.invoiceCnpj} - {record.invoiceCnpj}
                  </Alert>
                }

                {
                  (errors && errors.fuel && invoiceData) &&
                  <Alert variant="outlined" severity="error">
                    Combustível do abastecimento é diferente da nota: {invoiceData.fuel.name}
                  </Alert>
                }

                {
                  (errors && errors.amount && invoiceData) &&
                  <Alert variant="outlined" severity="error">
                    Quantidade em litros diferente do abastecimento: {(new Intl.NumberFormat('pt-BR', { style: 'unit', unit: 'liter' })).format(invoiceData.amount)}
                  </Alert>
                }

                {
                  (errors && errors.value && invoiceData) &&
                  <Alert variant="outlined" severity="error">
                    Valor total diferente do abastecimento: {formatPrice(invoiceData.value)}
                  </Alert>
                }
              </Stack>
          }
        </DialogContent>
        <DialogActions
          sx={{ marginTop: 0, padding: 3, paddingY: 2 }}
        >
          <Button
            disabled={fileLoading || saveLoading}
            type="reset"
            variant="outlined"
            onClick={handleClose}
            label="ra.action.close"
          />
          <Button
            disabled={fileLoading || !!errors || !invoiceData || saveLoading}
            variant="contained"
            onClick={handleSubmit}
            label="ra.action.confirm"
            startIcon={saveLoading ? <CircularProgress size={14} /> : <SaveIcon />}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

const Legend = styled('legend')({
  paddingLeft: 12,
  paddingRight: 12,
});

export default ImportInvoiceButton;