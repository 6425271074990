import { Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import jsonExport from 'jsonexport/dist';
import moment from 'moment';
import { useRef } from "react";
import { BooleanField, ChipField, Datagrid, DateField, DateInput, FormDataConsumer, FunctionField, ListBase, ListToolbar, NumberField, Pagination, ReferenceArrayField, ReferenceArrayInput, ReferenceField, ReferenceInput, SelectArrayInput, SelectInput, SingleFieldList, TextField, Title, TopToolbar, downloadCSV, fetchRelatedRecords, useDataProvider, useGetIdentity, useListContext, usePermissions } from 'react-admin';
import { CustomExportButton, CustomizableDatagrid, ExportButtonCustom, LazyLoadAutoCompleteInput, ListPagination, Permission, Permissions, PrintButton, ReportPrint } from '../../components';
import { formatCpf, getChainId, getCompanyId } from '../../utils/utils';

import { Card } from '@mui/material';
import { identity } from 'lodash';
import { PERMISSIONS } from '../../constants';
import { UserRole } from '../../providers/authProvider';
import { exportOptions, formatNumber } from "../../utils/utils";

const useStyles = makeStyles(theme => ({
  title: {
    margin: '10px 0 10px 0',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  headerCell: {
    backgroundColor: '#E5E5E5',
  },
  printButton: {},
  filterTablePrint: {
    display: 'none',
    '@media print': {
      display: 'block'
    },
    marginBottom: 10,
  },
  contentPagination: {
    '@media print': {
      display: 'none'
    },
  },
  titleCard: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
}));

const FillinsList = () => {
  const classes = useStyles();
  const props = useListContext();
  const { identity } = useGetIdentity();

  let data = [];
  let summaryData = [];

  if (props.data) {
    const fillinInfo = props.data[0];
    if (fillinInfo) {
      data = fillinInfo.data;
      summaryData = [fillinInfo.summaryData];
    }
  }

  return (
    <>
      <Typography className={classes.title}>Total</Typography>
      <Datagrid classes={{ headerCell: classes.headerCell }} style={{ marginBottom: 60 }} data={summaryData} total={1} bulkActionButtons={false}>
        <NumberField
          source="total_value"
          label="Total em Dinheiro"
          locales="pt-BR"
          textAlign="center"
          emptyText="R$ 0,00"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
          sortable={false}
        />
        <NumberField
          source="total_amount"
          label="Total em Litros"
          textAlign="center"
          emptyText="0"
          options={{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }}
          sortable={false}
        />
        <TextField source="total_fillins" textAlign="center" label="Total de abastecimentos" sortable={false} />
        <NumberField
          source="average_ticket"
          label="Ticket Médio"
          locales="pt-BR"
          textAlign="center"
          emptyText="R$ 0,00"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
          sortable={false}
        />
        <NumberField
          source="average_amount"
          label="Volume Médio"
          textAlign="center"
          emptyText="0"
          options={{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }}
          sortable={false}
        />
        <NumberField
          source="totalValueVoucher"
          label="Total em Vales"
          locales="pt-BR"
          textAlign="center"
          emptyText="R$ 0,00"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
          sortable={false}
        />
      </Datagrid>

      <Typography className={classes.title}>Abastecimento</Typography>
      <CustomizableDatagrid
        bulkActionButtons={false}
        classes={{ headerCell: { backgroundColor: '#E5E5E5' } }}
        resource="fillins"
        data={data}
        rowClick="show"
        defaultColumns={['station_id', 'chain_id', 'vehicle_id', 'value', 'diff_odometer', 'amount', 'price_per_liter', 'old_price_per_liter', 'fuel_id', 'received_at', 'deliveryId', 'invoice_id']}
      >
        <ReferenceField
          source="station_id"
          basePath="stations"
          reference="stations"
        >
          <TextField
            label="Posto"
            emptyText="Posto não identificado"
            source="name" />
        </ReferenceField>
        <ReferenceField label="Rede" emptyText="Não identificada" source="chain_id" basePath="chains" reference="chains" link={null}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField emptyText="Não identificado" label="Frentista" source="attendant_id" basePath="station-attendants" reference="station-attendants" link="show">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Motorista" emptyText="Não identificado" source="driver_id" basePath="drivers" reference="drivers" link="show">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Veículo" emptyText="Não identificado" source="vehicle_id" basePath="vehicles" reference="vehicles" link="show">
          <TextField source="licensePlate" />
        </ReferenceField>
        <TextField label="Odômetro" source="odometer" />
        <NumberField
          source="diff_odometer"
          label="Km Percorrido"
          locales="pt-BR"
          textAlign="center"
          emptyText="--"
          options={{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
        />
        <NumberField
          source="value"
          label="Valor"
          locales="pt-BR"
          textAlign="center"
          emptyText="R$ 0,000"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
        />
        <NumberField
          source="amount"
          label="Litros"
          textAlign="center"
          emptyText="0"
          options={{
            maximumFractionDigits: 2
          }}
        />
        <NumberField
          source="price_per_liter"
          label="Preço com desconto"
          locales="pt-BR"
          textAlign="center"
          emptyText="R$ 0,000"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 3,
            maximumFractionDigits: 3
          }}
        />
        <NumberField
          source="old_price_per_liter"
          label="Preço normal"
          locales="pt-BR"
          textAlign="center"
          emptyText="R$ 0,000"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 3,
            maximumFractionDigits: 3
          }}
        />
        <FunctionField
          label="Desconto"
          textAlign="center"
          render={record =>
            `${(record.old_price_per_liter && record.price_per_liter ? (record.old_price_per_liter - record.price_per_liter) * (record.amount || 0) : 0)
              .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}
        />
        <ReferenceField
          label="Combustível"
          emptyText="Não identificado"
          source="fuel_id"
          basePath="fuels"
          reference="fuels"
          link={(identity?.role === UserRole.admin) && "show"}
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="delivery_id" label="Identificador" />
        <DateField source="received_at" label="Data" showTime locales="pt-BR" />
        <BooleanField source="invoice_id" label="Nota Fiscal" looseValue />
      </CustomizableDatagrid >
    </>
  );
};

const VouchersList = () => {
  const classes = useStyles();
  const props = useListContext();
  const { identity } = useGetIdentity();

  return (
    <>
      <Typography className={classes.title} style={{ marginTop: 20 }}>Vales</Typography>
      <CustomizableDatagrid {...props} classes={{ headerCell: classes.headerCell }} rowClick="show" defaultColumns={['driver_id', 'vehicle_id', 'station_id', 'value', 'created_at']} bulkActionButtons={false}>
        <ReferenceField label="Motorista" source="driver_id" emptyText="Não identificado" basePath="drivers" reference="drivers" link={identity && [UserRole.admin, UserRole.company].includes(identity.role)}>
          <TextField source="name" />
        </ReferenceField>
        <Permissions userRoles={[UserRole.admin, UserRole.company]}>
          <ReferenceField label="Veículo" source="vehicle_id" emptyText="Não identificado" basePath="vehicles" reference="vehicles" link={identity && [UserRole.admin, UserRole.company].includes(identity.role)}>
            <TextField source="licensePlate" />
          </ReferenceField>
        </Permissions>
        <ReferenceField label="Posto" source="station_id" emptyText="Não identificado" basePath="stations" reference="stations" link={identity && [UserRole.admin, UserRole.chain].includes(identity.role)}>
          <TextField source="name" />
        </ReferenceField>
        <NumberField
          source="value"
          label="Valor"
          locales="pt-BR"
          textAlign="center"
          emptyText="R$ 0,000"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
        />
        <DateField source="created_at" label="Data" showTime locales="pt-BR" />
      </CustomizableDatagrid>
    </>
  );
};

const FilterTablePrint = () => {
  const classes = useStyles();
  const { filterValues } = useListContext();
  return (
    <Datagrid classes={{ headerCell: classes.headerCell }} data={[{ ...filterValues, generatedReportAt: new Date() }]} total={1} bulkActionButtons={false}>
      <DateField source="from" textAlign="center" label="De" sortable={false} locales="pt-BR" />
      <DateField source="to" textAlign="center" label="Até" sortable={false} locales="pt-BR" />
      <Permissions userRoles={[UserRole.admin, UserRole.chain]} label="Posto">
        <ReferenceField emptyText="Todos" source="stationId" textAlign="center" basePath="stations" reference="stations" link={false}>
          <TextField source="name" />
        </ReferenceField>
      </Permissions>
      <Permissions userRoles={[UserRole.admin, UserRole.company]} label="Motorista">
        <ReferenceField emptyText="Todos" source="driverId" textAlign="center" basePath="drivers" reference="drivers" link={false}>
          <TextField source="name" />
        </ReferenceField>
      </Permissions>
      <ReferenceArrayField label="Produtos" reference="fuels" source="fuelIds" optionText="name">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <TextField source="deliveryId" label="Identificador" />
      <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} locales="pt-BR" />
    </Datagrid>
  );
}

const TransactionsReport = (props) => {
  const classes = useStyles();
  const reportRef = useRef();
  const dataProvider = useDataProvider();
  const { permissions } = usePermissions();

  const listFilters = [
    <DateInput source="from" label="De" sx={{ marginBottom: 0.5 }} options={{ format: 'DD/MM/YYYY', disableFuture: true }} alwaysOn parse={(value) => moment(value).startOf('day').toISOString()} />,
    < DateInput source="to" label="Até" sx={{ marginBottom: 0.5 }} options={{ format: 'DD/MM/YYYY', disableFuture: true }} alwaysOn parse={(value) => moment(value).endOf('day').toISOString()} />,
    <Permission userRole={UserRole.admin} alwaysOn>
      <ReferenceInput
        label="Rede"
        source="chainId"
        reference="chains"
        sort={{ field: "name", order: "ASC" }}
        emptyText="Todos"
      >
        <SelectInput
          emptyText="Todos"
          label="Rede"
          optionText={'name'}
        />
      </ReferenceInput>
    </Permission>,
    <Permissions userRoles={[UserRole.admin, UserRole.chain]} alwaysOn>
      <ReferenceInput
        label="Posto"
        source="stationId"
        reference="stations"
        sort={{ field: "name", order: "ASC" }}
        emptyText="Todos"
        filter={identity?.role === UserRole.chain ? { chainId: getChainId() } : {}}
      >
        <SelectInput
          emptyText="Todos"
          label="Posto"
          optionText={'name'}
        />
      </ReferenceInput>
    </Permissions>,
    <Permission userRole={UserRole.admin} alwaysOn>
      <ReferenceInput
        label="Grupo de Transportadora"
        source="companyId"
        reference="companies"
        sort={{ field: "name", order: "ASC" }}
        emptyText="Todos"
      >
        <SelectInput
          emptyText="Todos"
          label="Transportadora"
          optionText={'name'}
        />
      </ReferenceInput>
    </Permission>,
    <Permissions userRoles={[UserRole.admin, UserRole.company]} alwaysOn>
      <ReferenceInput
        source="vehicleBaseId"
        reference="vehicle-bases"
        sort={{ field: "name", order: "ASC" }}
      >
        <SelectInput
          optionText={'name'}
          label="Filial"
          emptyText="Todos"
        />
      </ReferenceInput>
    </Permissions>,
    <Permissions userRoles={[UserRole.admin, UserRole.company]} alwaysOn>
      <FormDataConsumer>
        {({ formData }) => (
          <LazyLoadAutoCompleteInput
            label="Motorista"
            reference="drivers"
            source="driverId"
            format={choice => `${formatCpf(choice.cpf)} ${choice.register ? '- ' + choice.register : ''} - ${choice.name}`}
            style={{ minWidth: 160, marginBottom: 4 }}
            record={formData}
            size={"small"}
          />
        )}
      </FormDataConsumer>
    </Permissions>,
    <ReferenceArrayInput
      reference="fuels"
      source="fuelIds"
      sort={{ field: 'name', order: 'ASC' }}
      perPage={false}
      alwaysOn
    >
      <SelectArrayInput
        label="Produtos"
        optionText="name"
        style={{ paddingBottom: 4, paddingTop: 8 }}
      />
    </ReferenceArrayInput>,
  ];

  const ReportVoucher = () => {
    const { filterValues } = useListContext();

    return (
      <ListBase
        {...props}
        basePath="vouchers"
        resource="reports/vouchers"
        sort={{ field: 'created_at', order: 'DESC' }}
        perPage={25}
        filterDefaultValues={{
          ...filterValues,
          chainId: getChainId(),
          companyId: getCompanyId(),
        }}
        pagination={<ListPagination />}
        disableSyncWithLocation
      >
        <VouchersList />
        <div className={classes.contentPagination}>
          <Pagination />
        </div>
      </ListBase>
    );
  }

  const TransactionReportActions = () => {
    const { filterValues } = useListContext();

    return (
      <TopToolbar>
        <CustomExportButton fileName="relatorio_de_transacoes" preExport={(data,fetchRelatedRecords) =>  transactionsExporter(data,fetchRelatedRecords, filterValues )} />
        <PrintButton
          printRef={reportRef}
          className={classes.printButton}
        />
      </TopToolbar>
    );
  };


  const transactionsExporter = async (data, fetchRelatedRecords, filterValues) => {
    let fillinInfo = data[0].data;
    let voucherInfo;
    const relations = [
      { field: 'fuel_id', resource: 'fuels' },
      { field: 'station_id', resource: 'stations' },
      { field: 'vehicle_id', resource: 'vehicles' },
      { field: 'driver_id', resource: 'drivers' },
    ];

    const fetchData = await fetchRelatedRecords(relations, fillinInfo);

    const getData = (resource, dataResult) => {
      return dataResult.find(({ resource: res }) => res === resource)?.results || [];
    };

    fillinInfo = fillinInfo.map(fillin => {
      const { id, delivery_id, vehicle_id, driver_id, fuel_id, station_id, odometer, value, amount, hose_number, price_per_liter, old_price_per_liter, filling_completely, manually_added, received_at } = fillin;
      const station = getData('stations', fetchData).find(({ id }) => id === station_id);
      const fuel = getData('fuels', fetchData).find(({ id }) => id === fuel_id);
      const vehicle = getData('vehicles', fetchData).find(({ id }) => id === vehicle_id);
      const driver = getData('drivers', fetchData).find(({ id }) => id === driver_id);

      return {
        id,
        identificador: delivery_id,
        motorista: driver ? driver.name : 'Não identificado',
        posto: station ? station.name : 'Não identificado',
        placa: vehicle ? vehicle.licensePlate : 'Não identificado',
        combustivel: fuel ? fuel.name : 'Não identificado',
        odometro: odometer,
        valorTotal: value,
        litros: amount,
        desconto: old_price_per_liter && price_per_liter ? formatNumber(((old_price_per_liter - price_per_liter) * amount), { maximumFractionDigits: 2 }) : 0,
        precoPorLitro: price_per_liter,
        precoSemDescontoPorLitro: old_price_per_liter,
        numeroDoBico: hose_number,
        encheuTanque: filling_completely,
        registradoManualmente: manually_added,
        data: moment(received_at).format('DD/MM/YYYY HH:mm:ss'),
      }
    });

    // if (permissions && permissions.includes(PERMISSIONS.REPORT_VOUCHERS)) {
    //   // fillinInfo.push([{ report: 'vales' }]);
    //   const fetchVouchers = await new Promise(dataProvider.getList(`reports/vouchers`, { filter: { ...filterValues } }));
    //   const fetchDataVoucher = await fetchRelatedRecords(relations, fetchVouchers);
    //   voucherInfo = fetchVouchers.data.map(voucher => {
    //     const { id, driver_id, vehicle_id, station_id, value, created_at } = voucher;
    //     const station = getData('stations', fetchDataVoucher).find(({ id }) => id === station_id);
    //     const driver = getData('drivers', fetchDataVoucher).find(({ id }) => id === driver_id);
    //     const vehicle = getData('vehicles', fetchDataVoucher).find(({ id }) => id === vehicle_id);
    //     return {
    //       id,
    //       motorista: driver ? driver.name : '',
    //       veiculo: vehicle ? vehicle.licensePlate : '',
    //       posto: station ? station.name : '',
    //       valor: value,
    //       data: created_at
    //     };
    //   })

    // }
    return fillinInfo;

  }

  return (
    <>
      <ListBase
        {...props}
        basePath="fillins"
        resource="reports/fillins"
        sort={{ field: 'received_at', order: 'DESC' }}
        perPage={25}
        filter={{ generateFinancialMovement: true }}
        filterDefaultValues={{
          from: moment().subtract(1, 'month').startOf('day').toISOString(),
          to: moment().endOf('day').toISOString(),
          chainId: getChainId(),
          companyId: getCompanyId(),
        }}
        disableSyncWithLocation
      >
        <Title title="Transações" />
        <ListToolbar
          filters={listFilters}
          actions={<TransactionReportActions />}
        />
        <ReportPrint ref={reportRef} title={'Transações'} landscape={true}>
          <div className={classes.filterTablePrint}>
            <Typography className={classes.titleCard}>Filtros</Typography>
            <Card>
              <FilterTablePrint />
            </Card>
          </div>

          <Card>
            <FillinsList />
            <div className={classes.contentPagination}>
              <Pagination />
            </div>
            <Permissions permissions={[PERMISSIONS.REPORT_VOUCHERS]}>
              <ReportVoucher />
            </Permissions>
          </Card>
        </ReportPrint>
      </ListBase>
    </>
  )
};

export default TransactionsReport;