import { Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import moment from 'moment';
import { ArrayField, ChipField, Datagrid, DateField, DateInput, FormDataConsumer, FunctionField, NumberField, ReferenceArrayField, ReferenceArrayInput, ReferenceField, ReferenceInput, SelectArrayInput, SelectInput, SingleFieldList, TextField, useGetIdentity, useListContext, useRecordContext } from 'react-admin';
import { Permission, ReportList } from '../../components';
import { formatPrice, getCompanyId } from '../../utils/utils';

import { PERMISSIONS } from '../../constants';
import { UserRole } from '../../providers/authProvider';
import lodash from 'lodash';

const useStyles = makeStyles(theme => ({
  title: {
    margin: '10px 0 10px 0',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  headerCell: {
    backgroundColor: '#E5E5E5',
  },
}));

const calculateDeal = (record) => {
  if (!record || !record.price_per_liter || !record.old_price_per_liter) { return '--'; }

  const diff = record.old_price_per_liter - record.price_per_liter;

  if (diff === 0) { return 'Sem acordo'; }

  return `${diff > 0 ? 'Desconto' : 'Acréscimo'} ${formatPrice(diff)}`;
}

const calculateDiffBetweenActualValueAndBestPrice = (record) => {
  if (!record || !record.amount || !record.price_per_liter || !record.best_price) { return '--'; }

  const diff = (record.best_price * record.amount) - (record.price_per_liter * record.amount);

  return `${formatPrice(diff)}`;
}

const PumpPricesField = () => {
  const record = useRecordContext();
  return (
    <ChipField record={{ value: record ? formatPrice(record) : 'Não identificado' }} source="value" />
  );
}

const TotalValueUsingBestPriceField = ({ source }) => {
  const formatValue = (record) => {
    if (!record || !record.total_value || !record[source]) { return 'R$ 0,00'; }

    const diff = record[source] - record.total_value;

    return `${formatPrice(diff < 0 ? diff : 0)}`;
  }

  return (
    <FunctionField render={record => formatValue(record)} />
  );
}

const DealField = ({ source }) => {
  return (
    <FunctionField render={record => calculateDeal(record)} />
  );
}

const BestPriceField = () => {
  return (
    <FunctionField render={record => calculateDiffBetweenActualValueAndBestPrice(record)} />
  );
}

const bestPriceRowStyle = (record) => ({
  ...(record.best_price < record.price_per_liter ? {
    '& .column-price_per_liter': {
      backgroundColor: 'rgba(188, 55, 55, 0.5)',
    }
  } : {}),
});

const BestPricesList = () => {
  const classes = useStyles();
  const props = useListContext();
  const { identity } = useGetIdentity();

  let data = [];
  let summaryData = [];

  if (props.data) {
    const fillinInfo = props.data[0];
    if (fillinInfo) {
      data = fillinInfo.data.map(fillin => ({ ...fillin, pump_prices: lodash.sortBy(lodash.uniq(fillin.pump_prices || [])) }));
      summaryData = [fillinInfo.summaryData];
    }
  }

  return (
    <>
      <Typography className={classes.title}>Totais</Typography>
      <Datagrid classes={{ headerCell: classes.headerCell }} style={{ marginBottom: 60 }} data={summaryData} total={1} bulkActionButtons={false}>
        <NumberField
          source="count_fillin"
          label="Quantidade de abastecimentos"
          textAlign="center"
          emptyText="0"
          sortable={false}
        />
        <NumberField
          source="total_amount"
          label="Volume total"
          textAlign="center"
          emptyText="0"
          options={{
            style: 'unit',
            unit: 'liter',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }}
          sortable={false}
        />
        <NumberField
          source="total_value"
          label="Valor total"
          locales="pt-BR"
          textAlign="center"
          emptyText="R$ 0,00"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
          sortable={false}
        />
        <NumberField
          source="total_value_using_best_price"
          label="Valor total usando menor valor"
          locales="pt-BR"
          textAlign="center"
          emptyText="R$ 0,00"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
          sortable={false}
        />
        <TotalValueUsingBestPriceField
          source="total_value_using_best_price"
          label="possível economia"
          textAlign="center"
          sortable={false}
        />
      </Datagrid>

      <Typography className={classes.title}>Abastecimentos</Typography>
      <Datagrid
        bulkActionButtons={false}
        classes={{ headerCell: { backgroundColor: '#E5E5E5' } }}
        resource="fillins"
        data={data}
        rowClick="show"
        rowSx={bestPriceRowStyle}
      >
        <ReferenceField label="Rede" emptyText="Não identificada" source="chain_id" basePath="chains" reference="chains" link={null} sortable={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Posto"
          source="station_id"
          basePath="stations"
          reference="stations"
          textAlign="center"
          link="show"
          sortable={false}
        >
          <TextField
            label="Posto"
            emptyText="Posto não identificado"
            source="name" />
        </ReferenceField>
        <ReferenceField
          label="Combustível"
          emptyText="Não identificado"
          source="fuel_id"
          basePath="fuels"
          reference="fuels"
          textAlign="center"
          link={(identity?.role === UserRole.admin) && "show"}
          sortable={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ArrayField source="pump_prices" label="Preços disponíveis" sortable={false}>
          <SingleFieldList linkType={false}>
            <PumpPricesField />
          </SingleFieldList>
        </ArrayField>
        <DealField
          source="deal"
          label="Acordo"
          sortable={false}
        />
        <NumberField
          source="price_per_liter"
          label="Preço praticado"
          locales="pt-BR"
          textAlign="center"
          emptyText="R$ 0,000"
          cellClassName="price_per_liter"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 3,
            maximumFractionDigits: 3
          }}
        />
        <DateField source="received_at" label="Data" showTime locales="pt-BR" />
      </Datagrid>
    </>
  );
};

const FilterTablePrint = () => {
  const classes = useStyles();
  const { filterValues } = useListContext();
  return (
    <Datagrid classes={{ headerCell: classes.headerCell }} data={[{ ...filterValues, generatedReportAt: new Date() }]} total={1} bulkActionButtons={false}>
      <DateField source="from" textAlign="center" label="De" sortable={false} locales="pt-BR" />
      <DateField source="to" textAlign="center" label="Até" sortable={false} locales="pt-BR" />
      <ReferenceField label="Rede" reference="chains" source="chainId" emptyText="--" textAlign="center">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceArrayField label="Postos" reference="stations" source="stationIds" optionText="name">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField label="Produtos" reference="fuels" source="fuelIds" optionText="name">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} locales="pt-BR" />
    </Datagrid>
  );
}

const BestPricesReport = (props) => {

  const listFilters = [
    <DateInput source="from" label="De" sx={{ marginBottom: 0.5 }} options={{ format: 'DD/MM/YYYY', disableFuture: true }} alwaysOn parse={(value) => moment(value).startOf('day').toISOString()} />,
    <DateInput source="to" label="Até" sx={{ marginBottom: 0.5 }} options={{ format: 'DD/MM/YYYY', disableFuture: true }} alwaysOn parse={(value) => moment(value).endOf('day').toISOString()} />,
    <Permission userRole={UserRole.admin} alwaysOn>
      <ReferenceInput
        source="companyId"
        reference="companies"
        sort={{ field: "name", order: "ASC" }}
        emptyText="Todos"
      >
        <SelectInput
          emptyText="Todos"
          label="G. de transportadora"
          optionText={'name'}
        />
      </ReferenceInput>
    </Permission>,
    <ReferenceInput
      label="Rede"
      source="chainId"
      reference="chains"
      sort={{ field: "name", order: "ASC" }}
      emptyText="Todos"
      alwaysOn
    >
      <SelectInput
        emptyText="Todos"
        label="Rede"
        optionText={'name'}
      />
    </ReferenceInput>,
    <FormDataConsumer alwaysOn>
      {({ formData }) => (
        <ReferenceArrayInput
          reference="stations"
          source="stationIds"
          sort={{ field: 'name', order: 'ASC' }}
          filter={formData.chainId ? { chainId: formData.chainId } : {}}
          perPage={false}
          alwaysOn
        >
          <SelectArrayInput
            label="Postos"
            optionText="name"
            style={{ paddingBottom: 4, paddingTop: 8 }}
            disabled={!formData.chainId}
          />
        </ReferenceArrayInput>
      )}
    </FormDataConsumer>,
    <ReferenceArrayInput
      reference="fuels"
      source="fuelIds"
      sort={{ field: 'name', order: 'ASC' }}
      perPage={false}
      alwaysOn
    >
      <SelectArrayInput
        label="Produtos"
        optionText="name"
        style={{ paddingBottom: 4, paddingTop: 8 }}
      />
    </ReferenceArrayInput>,
    <SelectInput
      label="Tipo"
      source="withBestPrice"
      choices={[
        { id: true, name: 'Com Melhor Preço' },
        { id: false, name: 'Sem Melhor Preço' },
      ]}
      emptyText="Todos"
      alwaysOn
    />,
  ];

  const reportExporter = async (data, fetchRelatedRecords, filterValues) => {
    let fillinInfo = data[0].data;

    const relations = [
      { field: 'fuel_id', resource: 'fuels' },
      { field: 'chain_id', resource: 'chains' },
      { field: 'station_id', resource: 'stations' },
    ];

    const fetchData = await fetchRelatedRecords(relations, fillinInfo);

    const getData = (resource, dataResult) => {
      return dataResult.find(({ resource: res }) => res === resource)?.results || [];
    };

    fillinInfo = fillinInfo.map(fillin => {
      const { id, chain_id, fuel_id, station_id, value, amount, pump_prices, price_per_liter, received_at } = fillin;
      const chain = getData('chains', fetchData).find(({ id }) => id === chain_id);
      const station = getData('stations', fetchData).find(({ id }) => id === station_id);
      const fuel = getData('fuels', fetchData).find(({ id }) => id === fuel_id);

      return {
        id,
        rede: chain ? chain.name : 'Não identificado',
        posto: station ? station.name : 'Não identificado',
        combustivel: fuel ? fuel.name : 'Não identificado',
        precosDisponiveis: pump_prices ? pump_prices.map(price => formatPrice(price, { minimumFractionDigits: 3, maximumFractionDigits: 3 })).join(', ') : null,
        acordo: calculateDeal(fillin),
        precoPraticado: formatPrice(price_per_liter, { minimumFractionDigits: 3, maximumFractionDigits: 3 }),
        // diferenca: calculateDiffBetweenActualValueAndBestPrice(fillin),
        data: moment(received_at).format('DD/MM/YYYY HH:mm:ss'),
      }
    });

    return fillinInfo;
  }

  return (
    <Permission permission={PERMISSIONS.REPORT_BEST_PRICES}>
      <ReportList
        {...props}
        title="Melhor preço"
        basePath="fillins"
        resource="reports/best-prices"
        sort={{ field: 'received_at', order: 'DESC' }}
        perPage={25}
        filter={{ generateFinancialMovement: true }}
        filters={listFilters}
        filterDefaultValues={{
          from: moment().subtract(1, 'month').startOf('day').toISOString(),
          to: moment().endOf('day').toISOString(),
          companyId: getCompanyId(),
        }}
        filterTablePrint={<FilterTablePrint />}
        customExporter={reportExporter}
        fileName="relatorio_melhor_preco"
        disableSyncWithLocation
      >
        <BestPricesList />
      </ReportList>
    </Permission>
  );
};

export default BestPricesReport;