import React from 'react';
import { ImportInvoiceButton, ListPagination, Permission } from '../../components';
import { PERMISSIONS } from '../../constants';
import { Filter, List, ReferenceArrayInput, ReferenceInput, SelectArrayInput, SelectInput, DateInput } from 'ra-ui-materialui';
import { getChainId } from '../../utils/utils';
import { useListContext } from 'react-admin';
import { UserRole } from '../../providers/authProvider';
import dayjs from 'dayjs';
import { FillinDatagrid, ScreenType } from '../Fillin';
import { FillinReviewStatus, FillinStatus } from '../../models';

const InvoiceUpload: React.FC = (props) => {

  const ListFilters = (props: any) => {
    const { filterValues } = useListContext();

    return (
      <Filter {...props}>
        <DateInput
          source="from"
          label="De"
          sx={{ marginBottom: 0.5 }}
          alwaysOn
        />
        <DateInput
          source="to"
          label="Até"
          sx={{ marginBottom: 0.5 }}
          alwaysOn
        />
        {
          Permission({
            userRole: UserRole.admin,
            children: (
              <ReferenceInput
                source="chainId"
                reference="chains"
                alwaysOn
              >
                <SelectInput label="Rede" optionText="name" emptyText="Todos" />
              </ReferenceInput>
            )
          })
        }
        <ReferenceArrayInput
          reference="stations"
          source="stationIds"
          sort={{ field: 'name', order: 'ASC' }}
          //@ts-ignore
          perPage={false}
          filter={filterValues?.chainId ? { chainId: filterValues.chainId } : getChainId() ? { chainId: getChainId() } : {}}
          alwaysOn
        >
          <SelectArrayInput optionText="name" label="Postos" />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          reference="fuels"
          source="fuelIds"
          sort={{ field: 'name', order: 'ASC' }}
          //@ts-ignore
          perPage={false}
          alwaysOn
        >
          <SelectArrayInput optionText="name" label="Combustíveis" />
        </ReferenceArrayInput>
        <SelectInput
          source="hasInvoice"
          label="Nota fiscal"
          choices={[
            { id: true, name: 'Com nota' },
            { id: false, name: 'Sem nota' },
          ]}
          optionText="name"
          emptyText="Todos"
          alwaysOn
        />
      </Filter>
    );
  }

  const FillinList = () => {
    return (
      <FillinDatagrid
        screen={ScreenType.billShow}
        actions={(
          <ImportInvoiceButton />
        )}
      />
    );
  }

  const initialFilters = {
    status: FillinStatus.approved,
    stationReviewStatus: FillinReviewStatus.approved,
    generateFinancialMovement: true,
  }

  return (
    <Permission permission={PERMISSIONS.CREATE_INVOICE}>
      <List
        {...props}
        resource="fillins"
        perPage={25}
        pagination={<ListPagination />}
        exporter={false}
        title="importar nota fiscal"
        filter={getChainId() ? { chainId: getChainId(), ...initialFilters } : initialFilters}
        filterDefaultValues={{
          from: dayjs().subtract(1, 'month').startOf('day').toISOString(),
          to: dayjs().endOf('day').toISOString(),
          ...(getChainId() ? {
            chainId: getChainId(),
          } : {}),
        }}
        filters={<ListFilters {...props} />}
        hasCreate={false}
        storeKey={false}
      >
        <FillinList />
      </List>
    </Permission>
  );
}

export default InvoiceUpload;